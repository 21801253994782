import React, { useState } from 'react'
import { Wrapper } from './index.styles'
import { TextInput, Button } from 'grommet'
import { colors } from '../../theme'

const LearningGoalCreator = (props) => {
  const [title, setTitle] = useState('')

  return <Wrapper>
    <TextInput
      placeholder='Title'
      value={title}
      onChange={e => setTitle(e.target.value)}
    />
    <Button primary color={colors.green}>Add</Button>
  </Wrapper>
}

LearningGoalCreator.propTypes = {

}

LearningGoalCreator.defaultProps = {

}

export default () => {
  return <LearningGoalCreator />
}
