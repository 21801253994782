import React from 'react'
import Layout from '../../components/layout'
import LearningGoalCreator from '../../components/LearningGoalCreator'

const LearningGoalCreationPage = () => (
  <Layout>
    <LearningGoalCreator />
  </Layout>
)

export default LearningGoalCreationPage
