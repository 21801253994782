import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  > :nth-child(n) {
    margin-bottom: 10px;
  }
  > :last-child {
    margin-bottom: 0;
    align-self: flex-end;
  }
`
